import { SPACE } from "../common/constants";
import { styledProps } from "Common/types";
import { BASIC } from "Home/constants";
import styled from "styled-components";

export const Wrapper = styled.section<styledProps>(({ theme }) => ({
  padding: `${SPACE.xxl} ${SPACE.xl}`,
  backgroundColor: BASIC[theme].backgroundColor,

  h2: {
    marginBottom: SPACE.xl,
  },

  '.work-container': {
    display: 'flex',
    justifyContent: 'center', 
    gap: SPACE.l,
    flexWrap: 'wrap',
  },

  '.project-item': {
    width: '300px',
    padding: SPACE.l,
    borderRadius: SPACE.m,
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
  },

  '.project-item:hover': {
    transform: 'scale(1.05)',
  },

  img: {
    width: '100%',
    borderRadius: SPACE.m,
  },

  h3: {
    margin: `${SPACE.l} ${SPACE.zero} ${SPACE.m}`,
  },

  p: {
    fontSize: '0.9rem',
    color: BASIC[theme].mainTextColor,
  }
}));