import {ColorScheme} from '@vis.gl/react-google-maps';

export const FULL_NAME = "Yujie Zeng";
export enum SESSION_IDS {
    contact='contact',
    about='about',
    project='project',
    journey='journey',
    funThings='funThings',
};

export const THEME = {
    DARK: ColorScheme.DARK,
    LIGHT: ColorScheme.LIGHT
}

// Convert px to rem helper
// const pxToRem = (px: number) => `${px / 16}rem`;

/**
 * 4px = 0.25rem
 * 8px = 0.5rem
 * 12px = 0.75rem
 * 16px = 1rem
 * 20px = 1.25rem
 * 40px = 2.5rem
 */
export const SPACE = {
  zero: '0',
  xs: '0.25rem',
  s: '0.5rem',
  m: '0.75rem',
  l: '1rem',
  xl: '1.25rem',
  xxl: '2.5rem',
} as const;