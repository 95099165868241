import { AppContext } from "AppContext";
import { useContext } from "react";

import { SESSION_IDS } from "../common/constants";
import { Wrapper } from "./styled";
import { Item } from "./Item";

export const FunThingsSession = () => {
  const { theme } = useContext(AppContext);
  return (
    <Wrapper theme={theme} id={SESSION_IDS.funThings}>
      <h2>Fun Things</h2>
      <Item />
    </Wrapper>
  );
};
