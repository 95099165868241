import { AppContext } from "AppContext";
import { useContext } from "react";

import { SESSION_IDS } from "../common/constants";
import {
  ProfileContainer,
  ProfileDescription,
  ProfileHeading,
  ProfileImage,
  ProfileText,
} from "./styled";
import profile from "./images/profile2.jpg";


export const ProfileSession = () => {
  const { theme } = useContext(AppContext);

  return (
    <ProfileContainer id={SESSION_IDS.about}>
      <ProfileImage src={profile} alt="Profile" />
      <ProfileText>
        <ProfileHeading theme={theme}>YUJIE ZENG</ProfileHeading>
        <ProfileDescription theme={theme}>
          <p>
            I'm an adventurer at heart, always seeking out new experiences and places to explore. This website documents my interesting life, exciting travels, and the various hobbies and projects I dive into along the way. You'll find a mix of my travel plans, personal stories, and unique endeavors. Whether you're here to get some travel inspiration, follow along with my latest adventures, or just see what I'm up to, I hope you find something that sparks your interest.
          </p>
          <br />
          <p>
            Thanks for stopping by, and I hope you enjoy the journey as much as
            I do!
          </p>
        </ProfileDescription>
      </ProfileText>
    </ProfileContainer>
  );
};
