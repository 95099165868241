import { AppContext } from "AppContext";
import { useContext } from "react";

import { SESSION_IDS } from "../common/constants";
import { Wrapper } from "./styled";
import { Map } from "./map";
import { BASIC } from "Home/constants";

export const JourneySession = () => {
  const { theme } = useContext(AppContext);
  return (
    <Wrapper theme={theme} id={SESSION_IDS.journey}>
      <h2 style={{ color: BASIC[theme].mainTextColor }}>Journey - Click pin for details or download highlights</h2>
      <Map />
    </Wrapper>
  );
};
