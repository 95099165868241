import { useContext } from "react";

import { AppContext } from "AppContext";
import { FULL_NAME } from "../common/constants";
import { Footer } from "./styled";

export const FooterComponent = () => {
  const { theme } = useContext(AppContext);
    return <Footer theme={theme}>
    <p>&copy; 2025 {FULL_NAME}. All rights reserved.</p>
    <p>
      Follow me on <a href="https://www.linkedin.com/in/yujie-zeng-1128015a/">LinkedIn</a> | <a href="https://github.com/ArthaZeng?tab=repositories">Github</a>
    </p>
  </Footer>
};