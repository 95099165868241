import { THEME } from "../common/constants";

export const BASIC = {
  [THEME.LIGHT]: {
    backgroundColor: "#f4f4f4",
    color: "#333333",
    headerColor: "#f4f4f4",
    mainTextColor: "#333333",
    panelBackgroundColor: "#e0e0e0",
    secondaryTextColor: "#FFFFFF",
  },
  [THEME.DARK]: {
    backgroundColor: "#1E1E1E",
    color: "#e0e0e0",
    headerColor: "#FFFFFF",
    mainTextColor: "#FFFFFF",
    panelBackgroundColor: "#454545",
    secondaryTextColor: "#c6ffe6",
  },
};
