import styled from "styled-components";
import { BASIC } from "../Home/constants";
import { styledProps } from "Common/types";
import { SPACE } from "../common/constants";

export const Wrapper = styled.section<styledProps>(({ theme }) => {
  return {
    padding: `${SPACE.xxl} ${SPACE.l}`,
    backgroundColor: BASIC[theme].backgroundColor,
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "center",

    h2: {
      color: BASIC[theme].mainTextColor,
      writingMode: "vertical-rl",
      textOrientation: "mixed",
      margin: `${SPACE.zero} ${SPACE.xxl}`,
    },

    img: {
      width: "100%",
      borderRadius: SPACE.s,
    },

    h3: {
      margin: `${SPACE.l} ${SPACE.zero} ${SPACE.m}`,
    },

    p: {
      fontSize: "0.9rem",
      color: BASIC[theme].mainTextColor,
    },
  };
});

export const ItemWrapper = styled.button<styledProps>(({ theme }) => ({
  padding: SPACE.xl,
  borderRadius: SPACE.m,
  maxWidth: "900px",
  margin: `${SPACE.m} ${SPACE.zero}`,
  cursor: "pointer",
  backgroundColor: BASIC[theme].panelBackgroundColor,
}));

export const ImageGallery = styled.div<styledProps>(({ theme }) => ({
  width: "100%",
  marginRight: SPACE.xl,
}));

export const Image = styled.img<styledProps>(({ theme }) => ({
  width: "100px",
  height: "100px",
  objectFit: "cover",
  borderRadius: SPACE.s,
}));

export const Text = styled.div<styledProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
}));

export const Title = styled.p<styledProps>(({ theme }) => ({
  textAlign: "start",
  fontWeight: 700,
  margin: SPACE.zero,
  marginBottom: SPACE.m,
}));

export const Description = styled.p<styledProps>(({ theme }) => ({
  textAlign: "start",
  fontSize: "1rem",
  color: "#555",
}));
