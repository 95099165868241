import { SPACE } from "../common/constants";
import { styledProps } from "Common/types";
import { BASIC } from "Home/constants";
import styled from "styled-components";

export const Wrapper = styled.section<styledProps>(({ theme }) => ({
  padding: `${SPACE.xxl} ${SPACE.l}`,
  backgroundColor: BASIC[theme].backgroundColor,
  display: "flex",
  justifyContent: "center",

  h2: {
    marginBottom: SPACE.xl,
    color: BASIC[theme].mainTextColor,
    writingMode: "vertical-rl",
  },

  '.work-container': {
    display: 'flex',
    justifyContent: 'center',
    gap: SPACE.l,
    a: {
      color: BASIC[theme].mainTextColor,
      textDecoration: 'none',
    }
  },

  '.project-item': {
    width: '300px',
    background: BASIC[theme].panelBackgroundColor,
    padding: SPACE.l,
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s',
  },

  '.project-item:hover': {
    transform: 'scale(1.05)',
  },

  img: {
    width: '100%',
    borderRadius: '8px',
  },

  h3: {
    margin: `${SPACE.m} 0 ${SPACE.s}`,
  },

  p: {
    fontSize: '0.9rem',
    color: BASIC[theme].mainTextColor,
  },
}));
