import { AppContext } from "AppContext";
import { useContext, useState } from "react";

import {
  ItemWrapper,
  Image,
  ImageGallery,
  Text,
  Title,
  Description,
} from "./styled";

import { Modal } from "./Modal";
import { datumType, emptyDatum, data } from "./constants";


export const Item = () => {
  const { theme } = useContext(AppContext);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDatum, setSelectedDatum] = useState<datumType>(emptyDatum);

  const openModal = (datum: datumType) => () => {
    setIsModalOpen(true);
    if (datum.details) {
    setSelectedDatum(datum);
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedDatum(emptyDatum);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      {data.map((datum: datumType) => {
        const onClickContent = datum.href ? () => window.open(datum.href, "_blank") : openModal(datum);
        return (
          <ItemWrapper
            role="button"
            onClick={onClickContent}
            key={datum.title}
            theme={theme}
          >
            <Title theme={theme}>{datum.title}</Title>
            <Text theme={theme}>
              <ImageGallery theme={theme}>
                <Image src={datum.image} alt={datum.title} theme={theme} />
              </ImageGallery>
              <Description theme={theme}>{datum.description}</Description>
            </Text>
          </ItemWrapper>
        );
      })}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={selectedDatum?.title}
      >
        {selectedDatum.details && <selectedDatum.details />}
      </Modal>
    </div>
  );
};
