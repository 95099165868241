import { BASIC } from "Home/constants";
import { SPACE } from "../common/constants";
import styled from "styled-components";
import { styledProps } from "Common/types";

const highlightColor = "#61bc84";

export const ProfileContainer = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACE.xxl};
  background-image: linear-gradient(to right,#2E8B57, ${highlightColor});
`;

const profileImageSize = "200px";
export const ProfileImage = styled.img`
  width: ${profileImageSize};
  height: ${profileImageSize};
  border-radius: 50%;
  margin-right: ${SPACE.xl};
`;

export const ProfileText = styled.div`
  text-align: left;
`;

export const ProfileHeading = styled.h1<styledProps>(({ theme }) => ({
  margin: SPACE.zero,
  color: BASIC[theme].secondaryTextColor,
  fontSize: "3rem",
  padding: `${SPACE.l} ${SPACE.zero}`,
}));

export const ProfileDescription = styled.p<styledProps>(({ theme }) => ({
  fontSize: SPACE.l,
  color: BASIC[theme].secondaryTextColor,
  lineHeight: `${SPACE.xl}`,
}));
